<template>
  <div class="auth-page">
    <n-loader :loading="$var('load')" />
    <div v-if="isFirstTime" class="hello-screens">
      <div v-if="helloStep === 0" class="hello-screen first">
        <div class="text">
          <div class="header">
            EcoCashback
          </div>
          <div class="content">
            Мобильді қолданба тілін таңдаңыз<br>
            <br>
            Выберите язык мобильного приложения<br>
          </div>
          <div class="buttons">
            <n-button wide color="yellow" @click="selectLang('kz')">Қазақша</n-button>
            <n-button wide color="yellow" @click="selectLang('ru')">Русский</n-button>
          </div>
        </div>
      </div>
      <div v-if="helloStep === 1" class="hello-screen" @click="helloStep = 2">
        <div class="text">
          <div class="header">
            EcoCashback
          </div>
          <div class="content">
            <t name="1.1" />
          </div>
        </div>
        <div class="image-place">
          <n-icon icon="hello1" />
        </div>
        <div class="row">
          <n-icon icon="helloRow" />
        </div>
      </div>
      <div v-if="helloStep === 2" class="hello-screen" @click="helloStep = 3">
        <div class="text">
          <div class="header">
            EcoCashback
          </div>
          <div class="content">
            <t name="1.2" />
          </div>
        </div>
        <div class="image-place">
          <n-icon icon="hello2" />
        </div>
        <div class="row">
          <n-icon icon="helloRow" />
        </div>
      </div>
      <div v-if="helloStep === 3" class="hello-screen" @click="helloStep = 4">
        <div class="text">
          <div class="header">
            EcoCashback
          </div>
          <div class="content">
            <t name="1.3" />
          </div>
        </div>
        <div class="image-place">
          <n-icon icon="hello3" />
        </div>
        <div class="row">
          <n-icon icon="helloRow" />
        </div>
      </div>
      <div v-if="helloStep > 0" class="circles">
        <div class="circle" :class="{'active': helloStep >= 1}"></div>
        <div class="circle" :class="{'active': helloStep >= 2}"></div>
        <div class="circle" :class="{'active': helloStep >= 3}"></div>
      </div>
    </div>
    <div v-else class="container">
      <div v-if="!needPin" class="wrap">
        <div>
          <div class="info-wrap">
            <div class="logo">
              <n-icon icon="logo" />
            </div>

            <div class="title">
              <t name="2.1" />
            </div>

            <div class="subtitle">
              <t name="2.2" />
            </div>
          </div>

          <n-form>
            <n-items>
              <div class="item">
                <n-input v-bind="$form.input('phoneNumber')" :danger="$var('danger')" placeholder="+7" />
              </div>
              <div v-if="showPin" class="item">
                <t name="3.10" />
              </div>
              <div class="item">
                <c-code-input v-if="showPin" :code.sync="code" :danger="$var('code')" />
              </div>
              <n-button v-if="!showPin" color="yellow" wide @click="sendCode"><t name="3.9" /></n-button>
              <n-button v-if="showPin" color="yellow" wide @click="login"><t name="2.1" /></n-button>
            </n-items>
          </n-form>
        </div>

        <div v-if="!showPin" class="register">
          <n-link :to="{name: 'register'}" />
          <t name="2.6" />
        </div>
      </div>
      <div v-else class="wrap pin">
        <c-pin push-touch :danger-message="$var('pinMessage')" :finger.sync="finger"
               :value.sync="code" :need-finger="useFinger" @complete="() => {loginByCode()}" />
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/logo.png'
import CCodeInput from '../../components/code-input/Index.vue'

export default {
  name: 'Auth',
  components: { CCodeInput, },
  data() {
    return {
      code: '',
      finger: false,
      needPin: Object.keys((JSON.parse(localStorage.getItem('auth')) || {})).length !== 0,
      useFinger: JSON.parse(localStorage.getItem('auth'))?.user.useTouchId,
      logo,
      helloStep: 0,
      isFirstTime: !localStorage.getItem('isFirstTime'),
      showPin: false,
    }
  },
  computed: {
    form() {
      return this.$form.get('phoneNumber')
    },
  },
  watch: {
    'form'() {
      this.useMask('phone', this.$form.get('phoneNumber'))
    },
    helloStep() {
      if (this.helloStep === 4) {
        this.setIsFirstTime()
      }
    },
    finger() {
      this.loginByCode(this.finger)
    },
  },
  created() {
    this.$form.init({
      phoneNumber: '+7',
    })
    this.$form.rules({
      phoneNumber: [ 'required', ],
    })
    this.$var('passHide', true)
  },
  methods: {
    useMask(mask, value) {
      if (!/[A-Za-zА-Яа-яЁё]/.test(value) && value.length > 4) {
        this.$form.set('phoneNumber', this.applyMask(mask, value))
        this.maxlength = 18
      } else {
        this.$form.set('phoneNumber', value)
        this.maxlength = 32
      }
    },
    applyMask(mask, value) {
      if (mask === 'phone') {
        const digits = value.replace(/[^0-9]/g, '')

        let formattedNumber = '+7'

        if (digits.length > 1) {
          formattedNumber += ' (' + digits.slice(1, 4)
        }

        if (digits.length >= 5) {
          formattedNumber += ') ' + digits.slice(4, 7)
        }

        if (digits.length >= 8) {
          formattedNumber += ' ' + digits.slice(7, 9)
        }

        if (digits.length >= 10) {
          formattedNumber += '-' + digits.slice(9, 11)
        }

        return formattedNumber
      }

      return value
    },
    sendCode() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.auth.checkPhoneReset(this.$form.get('phoneNumber')).then(() => {
          $api.auth.sendSmsReset(this.$form.get('phoneNumber')).then(() => {
            this.showPin = true
          }).finally(() => {
            this.$var('load', false)
          })
        }).catch((err) => {
          this.$var('load', false)
          this.$var('danger', err.response.data.message)
        })
      }
    },
    selectLang(lang) {
      this.helloStep = 1

      $app.store.action('app.setLang', lang)
    },
    setIsFirstTime() {
      localStorage.setItem('isFirstTime', 'false')
      this.isFirstTime = false
    },
    login() {
      if (this.$form.check()) {
        this.$var('load', true)
        const data = {
          phoneNumber: this.$form.get('phoneNumber'),
          code: this.code,
        }
        $api.auth.login(data).then((response) => {
          const data = response.data.content
          $app.auth.login(data.user, data.token, data.grants)
        }).catch((error) => {
          this.$var('code', error.response.data.data.error[0])
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    loginByCode(finger = null) {
      if (finger) {
        this.$router.push({ name: 'index', })
      } else {
        this.$var('pinMessage', 'Неверный отпечаток')
      }
      if (this.code === localStorage.getItem('pin')) {
        this.$router.push({ name: 'index', })
      } else {
        this.$var('pinMessage', $app.secure.clean($n.t('20.10')))
      }
    },
    changeInputType(name) {
      const input = document.getElementById(name)
      if (input) {
        if (input.type === 'text') {
          input.type = 'password'
          this.$var(name + 'Hide', true)
        } else {
          input.type = 'text'
          this.$var(name + 'Hide', false)
        }
        input.focus()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.auth-page {
  //padding-top: calc(env(safe-area-inset-top) + 60px);


  .circles {
    width: 100vw;
    position: fixed;
    z-index: 5;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .circle {
      width: 32px;
      height: 32px;
      background-color: #FFFFFF;
      border: 1px solid #4da72e;
      border-radius: 50%;
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background-color: #156082;
        border: none;
      }
    }
  }
  .hello-screens {
    .hello-screen {
      height: 90vh;
      display: flex;
      position: relative;
      //align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .row {
        position: absolute;
        top: 24px;
        right: 0;
          svg {
            width: 64px;
            height: 32px;
          }
      }
      &.solo {
        justify-content: center;
      }
      &.first {
        justify-content: center;
        .buttons {
          margin-top: 20px;
          .n-button {
            margin-bottom: 10px;
          }
          .n-button:last-child {
            margin-bottom: 0;
          }
        }
      }
      .text {
        padding: 20px;
        .header {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .content {
          font-size: 20px;
          font-weight: 500;
        }
      }
      .image-place {
        //width: 100vw;
        //height: 250px;
        position: absolute;
        bottom: calc(env(safe-area-inset-bottom));
        left: 0;
        right: 0;
        ::v-deep svg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - calc(env(safe-area-inset-top) + 60px));
    padding-bottom: 32px;
    &.pin {
      justify-content: center;
    }
  }

  .logo{
    width: 300px;
    height: 100px;
    margin: 0 auto 24px;

    ::v-deep svg{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-wrap{
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;

    .title{
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 4px;
    }

    .subtitle{
      font-weight: 400;
      font-size: 16px;
    }
  }
  .n-form {
    .item {
      text-align: center;
      width: 100%;
      position: relative;

      .eye {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 9px;
        bottom: 7px;
        height: 30px;
        width: 30px;
      }
    }

    .forgot-pass{
      font-weight: 400;
      font-size: 16px;
      color: #8C8C8C;
    }

    .n-button{
      margin-top: 10px;
    }
  }

  .register{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border: 1px solid #FAAD14;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    color: #1E1E1E;
    position: relative;

    a{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}
</style>
